<template>
  <div class="container-fluid mt-3 test justify-content-between">
    <div class="row">
      <div class="col-12 col-md-12">
        <div v-if="wikiItem !== null">
          <h2 class="text-center">{{ wikiItem.title }}</h2>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-md-4 offset-md-4">
        <div v-if="wikiItem !== null">
          <img class="img img-fluid img-thumbnail" :src="wikiItem.url" />
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-md-8 offset-md-2">
        <div v-if="wikiItem !== null">
          <div
            v-for="(paragraph, i) in CreateParagraphs(wikiItem.content)"
            :key="paragraph"
          >
            <div v-if="i == 0">
              {{ paragraph }}
            </div>
            <div v-else><br />{{ paragraph }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Wiki from "../../Utils/Wiki/Wiki";
import CreateParagraphs from "../../Data/News/Editor";

export default {
  name: "WikiPage",
  data() {
    return {
      WikiData: [],
      wikiItem: null,
    };
  },
  methods: {
    CreateParagraphs,
    GetDetails(id) {
      let arr = [];
      Wiki.firstRow.forEach((item) => {
        arr.push(item);
      });
      Wiki.secondRow.forEach((item) => {
        arr.push(item);
      });
      Wiki.thirdRow.forEach((item) => {
        arr.push(item);
      });

      arr.forEach((item) => {
        if (id == item.id) {
          this.wikiItem = item;
        }
      });
    },
  },
  mounted() {
    this.params = this.$router.currentRoute.value.params;
    this.GetDetails(this.params.id);
  },
  watch: {
    $route() {
      this.params = this.$router.currentRoute.value.params;
    },
  },
};
</script>

<style scoped>
.test {
  text-align: justify;
}
</style>